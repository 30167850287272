<template>
    <div>
        <div v-if="!loading">
            <!-- MAIN CONTENT -->
            <div class="main-content">
                
                <div @click="$sidebar.displaySidebar(false)">
                    <fade-transition :duration="200" origin="center top" mode="out-in">

                        <b-row> 
                            <b-col lg="10" xl="8" offset-xl="2" offset-lg="1">

                                <ValidationObserver ref="form">
                                    <form @submit.prevent="onSubmit">
                                        <content-step-1 :itemData.sync="item_data" v-if="step === 1"/>
                                        <content-step-2 :itemData.sync="item_data" v-else-if="step === 2"/>
                                    </form>
                                </ValidationObserver>

                                <div v-if="step < 2" class="mt-4 mb-3 d-flex flex-wrap" >
                                    <div class="mb-2 mr-2"><app-button @click="add()" :loading="loading" :disabled="loading">Toevoegen</app-button></div>
                                    <div class="mr-2"><app-button class="app-local-btn-delete" @click="rejectFW()" :disabled="loading == true" :loading="loading">Weiger</app-button></div>
                                    <div><app-button class="app-local-btn-delete" @click="rejectFW(true)" :disabled="loading == true" :loading="loading">Weigeren en blokkeren</app-button></div>
                                </div>

                            </b-col>
                        </b-row>

                    </fade-transition>
                </div>
            </div>
        </div>

        <!-- LOADER HAPPY NURSE -->
        <b-row v-if="loading">
            <app-loader />
        </b-row>

    </div>
</template>
<script>
//KKY
import { FadeTransition } from 'vue2-transitions';
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppLoader from '@core/components/AppLoader.vue';
import AppAlerts from '@core/scripts/AppAlerts';
import ContentStep1 from './Components/ClientsOptInWizardStep1Component.vue';
import ContentStep2 from './Components/ClientsOptInWizardStep2Component.vue';

export default {
    components: {
        ValidationObserver,
        FadeTransition,
        AppLoader,
        ContentStep1,
        ContentStep2
    },

    created(){
        this.getData();
    },

    watch: {
        
    },

    computed: {

        classes() {
            let color = `bg-${this.type}`;
            let classes = [
                { 'navbar-transparent': this.transparent },
                { [`navbar-expand-${this.expand}`]: this.expand }
            ];
            if (this.position) {
                classes.push(`navbar-${this.position}`);
            }
            if (!this.transparent) {
                classes.push(color);
            }
            return classes;
        },
        hasMenu() {
            return this.$slots.default;
        }
       
    },
    
    data() {
        return {
            step: 1,
            item_data:{
                extern_id: null
            },

            loading: false,
            app_alerts: new AppAlerts(),

            show: false, 
            transparent: false,
            expand: 'md',
            menuClasses: '',
            containerClasses: 'container',
            type: '',
            mCancelDocument: {show: false,  args: {
                type: null,
                token: this.$route.params.token
            }, result: 0, return: null }
        };
    },
    
    
    methods: {
       getData(){

           this.loading = true;

           axios
                .get("clients/optin-wizard/getData/" + this.$route.params.token)
                .then((res) => {  
                    this.step = 1;              
                    this.item_data.result = res.data.result;
                })
                .catch((error) => {
                   console.error(`error during request: ${error}`);
                   this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });  
       },

        add(){
            this.$refs.form.validate().then((success) => {
                if (success) {
                    this.loading = true;

                    let form_data = new FormData();
                    form_data.append("item_data", JSON.stringify(this.item_data));

                    axios
                    .post("clients/optin-wizard/add/" + this.$route.params.token,  form_data)
                    .then((res) => {  
                        this.step = 2;              
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                        this.loading = false;
                    })
                    .finally(() => {
                        this.loading = false;
                    }); 
                }
            });
        },

        rejectFW(add_to_blocked = false){
            this.loading = true;

            axios
                .post("clients/optin-wizard/cancel/" + this.$route.params.token, {add_to_blocked: add_to_blocked})
                .then((res) => {  
                    this.step = 2;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                }); 
        },

        toggleMenu() {
            this.$emit('change', !this.show);
        },
        closeMenu() {
            this.$emit('change', false);
        }

    },

};
</script>

<style scoped>
    .app-local-btn-delete{
        background-color: #F04438;
        border-color: #F04438;
    }
</style>
