<template>
    <div>
        <div v-if="!loading">
            <h1 class="mt-2 mb-4">Formulier <span>plansysteem</span></h1>


            <b-overlay :show="loading" :opacity="0.0">

                <b-card class="mb-3">
                    <div class="app-card-profile-title mb-3">Geef hier het registratienummer op uit het plansysteem</div>
                    <b-row>
                        <b-col md="6" xl="6">
                            <app-row-left-label>
                                <app-input v-model="cItemData.extern_id" :validatorName="trans('extern-id', 226)" :label="trans('extern-id', 226)" validatorRules="required"/>
                            </app-row-left-label>
                        </b-col>
                        <b-col md="6" xl="6"></b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-card no-body class="mt-4 p-2 app-card-info">
                                <div class="app-info-card-label" v-if="loading">
                                    <b-spinner class="app-local-spinner"/>
                                </div>
                                <div class="app-info-card-label" v-if="!loading">
                                    <b-row>
                                        <b-col cols="6">
                                            <label class="flabel mb-0">{{trans('name',261)}}</label>
                                            <div class="mb-3 app-ellips" v-overflow-tooltip>{{ cItemData.result.first_name }} {{ cItemData.result.inbetween_name }} {{ cItemData.result.last_name }} {{ (cItemData.result.inbetween_maiden_name && cItemData.result.maiden_name) ? (` - ${cItemData.result.inbetween_maiden_name} ${cItemData.result.maiden_name}`) : '' }}</div>

                                            <label class="flabel mb-0">{{trans('function',253)}}</label>
                                            <div class="app-ellips" v-overflow-tooltip>{{ cItemData.result.function_name }}</div>
                                        </b-col>
                                        <b-col cols="6">
                                            <label class="flabel mb-0">{{trans('pdw-enter-email',233)}}</label>
                                            <div class="mb-3 app-ellips" v-overflow-tooltip>{{ cItemData.result.email }}</div>
                                        
                                            <label class="flabel mb-0">{{trans('pdw-enter-phone-number',233)}}</label>
                                            <div class="mb-3 app-ellips" v-overflow-tooltip>{{ cItemData.result.phone_prefix }} {{ cItemData.result.phone_number }}</div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-card>

                

                <template #overlay>
                    <div></div>
                </template>
            </b-overlay>

        </div>
        <b-row v-if="loading">
            <app-loader />
        </b-row>
    </div>
</template>

<script>

import { ValidationObserver } from "vee-validate";
import AppLoader from '@core/components/AppLoader.vue';


export default {
    components: {
        AppLoader,
    },
    props: ["itemData", "loading"],
    
    mounted(){
       
    },

    watch:{

    },

    computed: {
        cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }, 
        }
    },

    data(){
        return{
          
        }
    },

    methods:{

    }
};
</script>
<style></style>
<style lang="scss">
</style>
